import { BREAKPOINTS, STORAGE } from '@scripts/utils/constants'
import store from '@scripts/utils/storage'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

class MainNavigation {
    private namespace: string = 'navigation'
    private toggle: HTMLButtonElement | null
    private overlay: HTMLDivElement | null
    private menu: HTMLDivElement | null
    private items: NodeListOf<HTMLLIElement> | null
    private extras: NodeListOf<HTMLUListElement> | null
    private background: SVGElement | null
    private timeline?: GSAPTimeline

    constructor() {
        console.info('[%s] bootstrap', this.namespace)

        this.toggle = document.querySelector('.toggle')
        this.overlay = document.querySelector('.main-navigation--overlay')
        this.menu = document.querySelector('.main-navigation--menus')
        this.items = document.querySelectorAll('.main-navigation--menus .menu-categories li, .main-navigation--menus .menu-main li')
        this.extras = document.querySelectorAll('.main-navigation--menus .menu-social, .main-navigation--menus .menu-external')
        this.background = document.querySelector('.main-navigation .sign-background')
    }

    public start() {
        console.info('[%s] started', this.namespace)

        store?.subscribe(() => {
            this.toggleMainMenu()
        })

        this.bootstrapTween()
        this.setEventListeners()
    }

    private bootstrapTween() {
        console.info('[%s] bootstrap tween', this.namespace)

        window.innerWidth >= BREAKPOINTS.LG ? this.clearTimeline() : this.buildTimeline()
    }

    private clearTimeline() {
        console.info('[%s] clearTimeline', this.namespace)
        const targetElements: Element[] = []
        this.timeline?.clear()
        this.timeline = undefined
        this.toggle?.classList.remove('active')

        this.overlay && targetElements.push(this.overlay)
        this.menu && targetElements.push(this.menu)
        this.items && targetElements.push(...this.items)
        this.extras && targetElements.push(...this.extras)
        this.background && targetElements.push(this.background)

        store.dispatch({ type: STORAGE.ACTION_MENU_HIDE })
        gsap.set(targetElements, { clearProps: true })
        enableBodyScroll(document.body)
    }

    private buildTimeline() {
        this.toggle?.classList.remove('active')

        if (!this.timeline) {
            this.timeline = gsap.timeline({
                paused: true,
                onStart: () => {
                    disableBodyScroll(document.body)
                    this.toggle?.classList.add('active')
                },
                onReverseComplete: () => {
                    enableBodyScroll(document.body)
                    this.toggle?.classList.remove('active')
                }
            })

            this.overlay &&
                this.timeline.fromTo(
                    this.overlay,
                    {
                        autoAlpha: 0
                    },
                    {
                        autoAlpha: 0.8,
                        ease: 'power2.out',
                        yoyo: true,
                        duration: 1
                    },
                    0
                )

            this.menu &&
                this.timeline.fromTo(
                    this.menu,
                    { yPercent: 0 },
                    {
                        yPercent: 100,
                        ease: 'power2.inOut',
                        duration: 1
                    },
                    0
                )

            this.background &&
                this.timeline.fromTo(
                    this.background,
                    {
                        autoAlpha: 0,
                        y: 300
                    },
                    {
                        autoAlpha: 0.5,
                        y: 0,
                        ease: 'power2.out',
                        duration: 3
                    },
                    1
                )

            this.items &&
                this.timeline.fromTo(
                    this.items,
                    {
                        autoAlpha: 0,
                        x: '100vw'
                    },
                    {
                        autoAlpha: 1,
                        x: 0,
                        duration: 2,
                        ease: 'power2.out',
                        stagger: {
                            each: 0.1,
                            ease: 'power2.out'
                        }
                    },
                    0.6
                )

            this.extras &&
                this.timeline.fromTo(
                    this.extras,
                    {
                        autoAlpha: 0,
                        x: '100vw'
                    },
                    {
                        autoAlpha: 1,
                        x: 0,
                        duration: 2,
                        ease: 'power2.out',
                        yoyo: true
                    },
                    1.3
                )

            this.timeline.reverse()
        }
    }

    private setEventListeners() {
        window.addEventListener('resize', () => this.bootstrapTween())

        this.toggle?.addEventListener('click', () => {
            store.dispatch({ type: STORAGE.ACTION_MENU_TOGGLE })
        })
    }

    private toggleMainMenu() {
        const { menuVisible } = store.getState()
        this.timeline?.duration(menuVisible ? 2 : 1).reversed(!menuVisible)
    }
}

export default MainNavigation
