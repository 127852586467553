enum BREAKPOINTS {
    MD = 768,
    LG = 1024,
    XL = 1440
}

enum STORAGE {
    ACTION_MENU_TOGGLE = 'menu_toggle',
    ACTION_MENU_SHOW = 'menu_show',
    ACTION_MENU_HIDE = 'menu_hide'
}

export { BREAKPOINTS, STORAGE }
