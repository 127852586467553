import { AppStates } from '@definitions/storage'
import { configureStore, createAction, createReducer, Store } from '@reduxjs/toolkit'
import { STORAGE } from '@scripts/utils/constants'

const defaultState: AppStates = { menuVisible: false }

const menuToggle = createAction(STORAGE.ACTION_MENU_TOGGLE)
const showMenu = createAction(STORAGE.ACTION_MENU_SHOW)
const hideMenu = createAction(STORAGE.ACTION_MENU_HIDE)
const reducer = createReducer(defaultState, builder => {
    builder.addCase(menuToggle, state => {
        state.menuVisible = !state.menuVisible
    })

    builder.addCase(showMenu, state => {
        state.menuVisible = true
    })

    builder.addCase(hideMenu, state => {
        state.menuVisible = false
    })
})

const store: Store = configureStore({ reducer })

export { menuToggle }
export default store
