import Carousel from '@scripts/components/Carousel'
import Swiper from 'swiper'
import { Controller } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types'

class HeroCarousel extends Carousel {
    protected namespace: string = 'hero-carousel'
    protected selector: string = '.component--hero-carousel'
    protected controlledSwiper: string = '.component--hero-carousel-right .swiper'
    protected carouselConfigurations: SwiperOptions = {
        slidesPerView: 1,
        speed: 800,
        autoplay: {
            delay: 3000
        }
    }

    private controlledElement?: HTMLElement | null

    protected setElements(): void {
        super.setElements()
        this.controlledElement = document.querySelector(this.controlledSwiper)
    }

    protected bootstrap(): void {
        super.bootstrap()
        this.bootStrapControlledCarousel()
    }

    protected bootStrapControlledCarousel(): void {
        if (this.controlledElement) {
            const swiperInstance = new Swiper(this.controlledElement, {
                ...this.carouselConfigurations,
                modules: [Controller],
                controller: {
                    control: this.instances?.[0]
                },
                init: false
            })

            swiperInstance && this.instances && this.instances.length && (this.instances[0].controller.control = swiperInstance)

            document.querySelector(this.selector)?.addEventListener('mouseenter', () => {
                this.instances?.[0].autoplay?.stop()
            })

            document.querySelector(this.selector)?.addEventListener('mouseleave', () => {
                this.instances?.[0].autoplay?.start()
            })

            swiperInstance.init()
        }
    }
}

export default HeroCarousel
