import Swiper from 'swiper'
import { Autoplay, Controller, Navigation, Pagination } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types'

class Carousel {
    protected selector: string = '.component--carousel'
    protected swiper: string = '.swiper'
    protected pagination: string = '.swiper-pagination'
    protected previous: string = '.swiper-button-prev'
    protected next: string = '.swiper-button-next'
    protected namespace: string = 'carousel'
    protected initTimeout: number = 1800
    protected carouselBaseConfigurations: SwiperOptions = {
        modules: [Pagination, Navigation, Controller],
        init: false
    }
    protected carouselConfigurations: SwiperOptions = {
        slidesPerView: 1,
        speed: 800,
        breakpoints: {
            768: {
                slidesPerView: 2
            },
            1024: {
                slidesPerView: 3
            }
        }
    }
    protected elements?: NodeListOf<HTMLElement> | null
    protected instances: Array<Swiper> = []

    constructor() {
        console.info('[%s] bootstrap', this.namespace)
    }

    public start(): void {
        console.info('[%s] start', this.namespace)
        this.setElements()
        this.bootstrap()
    }

    protected setElements(): void {
        console.info('[%s] set elements', this.namespace)
        this.elements = document.querySelectorAll(this.selector)
    }

    protected bootstrap(): void {
        console.info('[%s] bootstrap', this.namespace)
        this.carouselConfigurations.autoplay && this.carouselBaseConfigurations.modules?.push(Autoplay)
        this.bootstrapCarousels()
    }

    private bootstrapCarousels(): void {
        console.info('[%s] bootstrap carousels', this.namespace)
        this.elements?.forEach((element: HTMLElement) => {
            const swiperElement: HTMLElement | null = element.querySelector(this.swiper)
            const swiperPagination: HTMLElement | null = element.querySelector(this.pagination)
            const swiperPrevEl: HTMLElement | null = element.querySelector(this.previous)
            const swiperNextEl: HTMLElement | null = element.querySelector(this.next)
            const config: SwiperOptions = {
                ...this.carouselBaseConfigurations,
                ...this.carouselConfigurations,
                on: {
                    resize: (swiper: Swiper) => {
                        swiper.isLocked ? element.classList.add('carousel-is-locked') : element.classList.remove('carousel-is-locked')
                    }
                }
            }

            swiperPagination instanceof HTMLElement &&
                (config.pagination = {
                    el: swiperPagination,
                    type: 'progressbar'
                })

            swiperPrevEl instanceof HTMLElement &&
                swiperNextEl instanceof HTMLElement &&
                (config.navigation = {
                    prevEl: swiperPrevEl,
                    nextEl: swiperNextEl
                })

            if (swiperElement instanceof HTMLElement) {
                const swiperInstance = new Swiper(swiperElement, config)

                swiperInstance.init()
                swiperInstance.autoplay?.stop()

                this.instances.push(swiperInstance)

                setTimeout(() => {
                    swiperInstance.autoplay?.start()
                }, this.initTimeout)
            }
        })
    }
}

export default Carousel
