class BackTop {
    private namespace: string = 'backtop'
    private buttons?: NodeListOf<HTMLButtonElement> | null

    constructor() {
        console.info('[%s] bootstrap', this.namespace)
        this.buttons = document.querySelectorAll('.back-top')
    }

    public start(): void {
        console.info('[%s] start', this.namespace)
        this.setEventListeners()
    }

    private setEventListeners(): void {
        this.buttons?.forEach((button: HTMLButtonElement) => {
            button.addEventListener('click', (e: MouseEvent) => {
                e && e.preventDefault()
                window.scrollTo(0, 0)
            })
        })
    }
}

export default BackTop
