import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import gsap from 'gsap'

class PageHeader {
    protected element?: HTMLElement | null
    protected timeline?: GSAPTimeline
    protected signBackgroundTimeline?: GSAPTimeline
    private namespace: string = 'page-header'

    constructor() {
        console.log('[%s] bootstrap', this.namespace)
        this.element = document.querySelector('.page-header')
    }

    start() {
        console.log('[%s] start', this.namespace)

        this.bootstrapSignBackgroundTimeline()
        this.bootstrapTimeline()
        document.body.classList.contains('is-home') ? this.bootstrapHomeHeaderTween() : this.bootstrapContentHeaderTween()
    }

    private onTweenStart() {
        disableBodyScroll(document.body)
    }

    private onTweenComplete() {
        enableBodyScroll(document.body)
        document.body.classList.add('is-active')
        this.signBackgroundTimeline?.play()
    }

    private bootstrapSignBackgroundTimeline() {
        const signBackground = this.element?.querySelector('.sign-background')

        this.signBackgroundTimeline = gsap.timeline({
            paused: true
        })

        signBackground &&
            this.signBackgroundTimeline?.from(signBackground, {
                autoAlpha: 0,
                y: 100,
                rotation: 4,
                duration: 6,
                clearProps: true,
                ease: 'power2.out'
            })
    }

    private bootstrapTimeline() {
        const background = this.element?.querySelector('.page-header--background')

        this.timeline = gsap.timeline({
            paused: true,
            onStart: () => this.onTweenStart(),
            onComplete: () => this.onTweenComplete()
        })

        background &&
            this.timeline?.from(
                background,
                {
                    scaleY: 0,
                    duration: 1.6,
                    clearProps: true,
                    ease: 'power2.inOut'
                },
                0
            )
    }

    private bootstrapHomeHeaderTween() {
        const pageTitle = this.element?.querySelector('.page-header--title')
        const pageIntro = this.element?.querySelector('.page-header--introduction .component--markdown')
        const domains = document.querySelectorAll('.domain-navigation--item-content')
        const domainsContents = document.querySelectorAll('.domain-navigation--item-content .name, .domain-navigation--item-content .excerpt, .domain-navigation--item-content .cta')

        pageTitle &&
            this.timeline?.from(
                pageTitle,
                {
                    yPercent: 150,
                    duration: 2,
                    clearProps: true,
                    ease: 'power2.inOut'
                },
                0.2
            )

        pageIntro &&
            this.timeline?.from(
                pageIntro,
                {
                    autoAlpha: 0,
                    duration: 1,
                    clearProps: true,
                    ease: 'power3.inOut'
                },
                1.3
            )

        domains &&
            domains.length &&
            this.timeline?.from(
                domains,
                {
                    yPercent: 100,
                    background: 'rgb(24, 42, 123)',
                    duration: 1,
                    ease: 'power4.out',
                    clearProps: true,
                    stagger: {
                        each: 0.16,
                        ease: 'power2.out'
                    }
                },
                0.8
            )

        domainsContents &&
            domainsContents.length &&
            this.timeline?.from(
                domainsContents,
                {
                    autoAlpha: 0,
                    duration: 1,
                    clearProps: true,
                    ease: 'power3.inOut'
                },
                1.3
            )

        this.timeline?.duration(2).play()
    }

    private bootstrapContentHeaderTween() {
        const pageOverTitle = this.element?.querySelector('.page-header--overtitle')
        const pageTitle = this.element?.querySelector('.page-header--title')
        const menus = this.element?.querySelectorAll('.menu-category--item')

        pageOverTitle &&
            this.timeline?.from(
                pageOverTitle,
                {
                    xPercent: 10,
                    autoAlpha: 0,
                    duration: 1.3,
                    clearProps: true,
                    ease: 'back.inOut(1.7)'
                },
                1
            )

        pageTitle &&
            this.timeline?.from(
                pageTitle,
                {
                    xPercent: -10,
                    autoAlpha: 0,
                    duration: 1.4,
                    clearProps: true,
                    ease: 'back.inOut(1.7)'
                },
                1
            )

        menus &&
            menus.length &&
            this.timeline?.from(
                menus,
                {
                    autoAlpha: 0,
                    duration: 0.8,
                    clearProps: true,
                    ease: 'power2.inOut',
                    stagger: {
                        each: 0.1,
                        ease: 'power2.in'
                    }
                },
                1
            )

        this.timeline?.duration(1.8).play()
    }
}

export default PageHeader
