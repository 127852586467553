class ImageLazyLoad {
    private namespace: string = 'imagelazyload'
    private elements?: NodeListOf<HTMLImageElement> | null

    constructor() {
        console.info('[%s] bootstrap', this.namespace)
        this.elements = document.querySelectorAll('img.lazyload')
    }

    public start(): void {
        console.info('[%s] start', this.namespace)
        this.setEventListeners()
    }

    private setEventListeners(): void {
        this.elements?.forEach((media: HTMLImageElement) => {
            const sources: NodeListOf<HTMLSourceElement> | undefined = media.parentNode?.querySelectorAll('source')
            const img: HTMLImageElement | null | undefined = media.parentNode?.querySelector('img')

            img?.addEventListener('load', () => {
                media.classList.remove('lazyload')
            })

            img && img.dataset.src && img.setAttribute('src', img.dataset.src)

            sources?.forEach((source: HTMLSourceElement) => {
                source.dataset.srcset && source.setAttribute('srcset', source.dataset.srcset)
            })
        })
    }
}

export default ImageLazyLoad
