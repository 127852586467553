class Form {
    protected namespace: string = 'team'
    protected navigations?: NodeListOf<HTMLLIElement>
    protected elements?: NodeListOf<HTMLFormElement>

    constructor() {
        console.info('[%s] bootstrap', this.namespace)
    }

    public start(): void {
        console.info('[%s] start', this.namespace)
        this.elements = document.querySelectorAll('.form-wrapper form')
        this.navigations = document.querySelectorAll('.form--contact-navigation a')
        this.bootstrapNavigations()
        this.bootstrapForms()
    }

    protected bootstrapNavigations(): void {
        this.navigations?.forEach(navigation => {
            navigation.addEventListener('click', (event: Event) => {
                event.preventDefault()
                const hash = navigation.getAttribute('href')
                const formElements = document.querySelectorAll('.form-wrapper')

                if (hash) {
                    const currentFormElement = document.querySelector(hash)

                    this.navigations?.forEach((el: Element) => {
                        el.classList.remove('active')
                    })

                    formElements?.forEach((formElement: Element) => {
                        formElement.classList.remove('active')
                    })

                    navigation.classList.add('active')
                    currentFormElement?.classList.add('active')
                    window.location.hash = hash
                }
            })
        })
    }

    protected bootstrapForms(): void {
        this.elements?.forEach((element: HTMLFormElement) => {
            const messageContainer = element.querySelector('.form-messages')

            this.bootstrapFakeFileFields(element.querySelectorAll('.form-file'))
            // this.handleFieldsChange(element)

            element.addEventListener('submit', async (event: Event) => {
                event.preventDefault()
                const formData = new FormData(element)
                const fetchRequest = new Request(element.action)

                this.resetFormFieldsErrors(element)

                element.classList.add('processing')

                try {
                    const response = await fetch(fetchRequest, {
                        method: 'POST',
                        headers: new (window as any).Headers({
                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept': 'application/json',
                            'X-Allow-Partial': 'yes'
                        }),
                        mode: 'cors',
                        cache: 'default',
                        body: formData,
                        credentials: 'same-origin'
                    })

                    const data = await response.json()
                    const { message, errorsPerForm } = data

                    message && messageContainer && (messageContainer.textContent = message)
                    errorsPerForm &&
                        Object.keys(errorsPerForm).forEach((key: string) => {
                            const field = element.querySelector(`.form-${key.replace('_', '-')}`)

                            if (field instanceof HTMLElement) {
                                const fieldMessage = document.createElement('div')
                                fieldMessage.classList.add('error-message')
                                fieldMessage.textContent = errorsPerForm[key]
                                field.classList.add('has-error')
                                field.appendChild(fieldMessage)
                            }
                        })

                    element.classList.remove('processing')
                } catch (error) {
                    console.error(error)
                    element.classList.remove('processing')
                }
            })
        })
    }

    protected bootstrapFakeFileFields(fields: NodeListOf<HTMLElement>): void {
        fields.forEach(field => {
            const input = field.querySelector('input')
            const state: HTMLElement | null = field.querySelector('.state')
            const currentState = state?.textContent || 'Select a file'

            input?.addEventListener('change', ({ target }: Event) => {
                let fileName

                if (input.files && input.files.length > 1) {
                    fileName = ((target as HTMLInputElement).getAttribute('data-multiple-caption') || '').replace('{count}', input.files.length.toString())
                } else {
                    fileName = (target as HTMLInputElement).value.split('\\').pop()
                }

                state && (state.textContent = fileName || currentState)
            })
        })
    }

    protected resetFormFieldsErrors(form: HTMLFormElement): void {
        const fields = form.querySelectorAll('.form-group')
        const formMessage = form.querySelector('.form-messages')

        fields.forEach((field: Element) => {
            field.classList.remove('has-error')
            field.querySelector('.error-message')?.remove()
        })

        formMessage && (formMessage.textContent = '')
    }

    protected handleFieldsChange(form: HTMLFormElement): void {
        const fields = form.querySelectorAll('.form-group')

        fields.forEach((field: Element) => {
            const input = field.querySelector('input, textarea')

            input?.addEventListener('change', () => {
                const message = field.querySelector('.error-message')
                message && message.remove()
            })
        })
    }
}

export default Form
