class Team {
    protected namespace: string = 'team'
    protected elements?: NodeListOf<HTMLElement>

    constructor() {
        console.info('[%s] bootstrap', this.namespace)
    }

    public start(): void {
        console.info('[%s] start', this.namespace)
        this.elements = document.querySelectorAll('.page--personlisting-wrapper')
        this.bootstrapTeamView()
    }

    protected bootstrapTeamView(): void {
        this.elements?.forEach((element: HTMLElement) => {
            let current
            const filters: NodeListOf<HTMLElement> | null = element.querySelectorAll('.component--filters-tags-item')
            const items: NodeListOf<HTMLElement> | null = element.querySelectorAll('.component--person')

            if (filters.length) {
                const first: HTMLElement = filters[0]
                first && (current = first.dataset.tag)
                current && this.updateActiveItems(filters, items, current)

                filters.forEach((item: HTMLElement) => {
                    item.addEventListener('click', () => {
                        this.updateActiveItems(filters, items, item.dataset.tag)
                    })
                })
            } else {
                items &&
                    items.length &&
                    items.forEach((item: HTMLElement): void => {
                        item.classList.add('active')
                    })
            }
        })
    }

    protected updateActiveItems(filters: NodeListOf<HTMLElement>, items: NodeListOf<HTMLElement>, slug?: string): void {
        if (slug) {
            filters &&
                filters.length &&
                filters.forEach((item: HTMLElement): void => {
                    item.dataset.tag === slug ? item.classList.add('active') : item.classList.remove('active')
                })

            items &&
                items.length &&
                items.forEach((item: HTMLElement): void => {
                    item.classList.contains(slug) ? item.classList.add('active') : item.classList.remove('active')
                })
        }
    }
}

export default Team
